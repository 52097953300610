import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRegDrugs, IRegDrug, ServiceRegDrugs } from 'services/reg-drugs';
import { AppAsyncThunkConfig } from 'store';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { IGridRegDrug, NAMESPACE, SELECT } from './helpers';
import {
  selectRegDrugsFilters,
  selectRegDrugsOrderBy,
  selectRegDrugsPagination,
} from './selectors';

const makeFilters = (filters: ReturnType<typeof selectRegDrugsFilters>) => {
  const { customerID, search, dateRange } = filters;
  return dynamic
    .mergeFilters(
      dynamic.makeFilter('customerID', customerID, dynamic.decoratorIsNotNullable(dynamic.equals)),
      dynamic.makeFilter(['title', 'customer.companyName'], search, dynamic.contains),
      dynamic.makeFilter('publishDate', dateRange, dynamic.dateRange),
    )
    .join('&&');
};

export const actionRegDrugsGetAllDynamic = createAsyncThunk<
  { value: IGridRegDrug[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`${NAMESPACE}/actionRegDrugsGetAllDynamic`, async (_, { getState }) => {
  try {
    const filters = selectRegDrugsFilters(getState());
    const { take, skip } = selectRegDrugsPagination(getState());
    const { field, order } = selectRegDrugsOrderBy(getState());

    const params = {
      filter: makeFilters(filters),
      select: SELECT,
      orderBy: dynamic.orderBy(field, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceRegDrugs.getAllDynamic<IGridRegDrug, typeof params>(
      params,
    );

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionRegDrugsGetExportData = createAsyncThunk<
  IGridRegDrug[],
  { ignorePagination: boolean },
  AppAsyncThunkConfig
>(`${NAMESPACE}/actionRegDrugsGetExportData`, async ({ ignorePagination }, { getState }) => {
  try {
    const filters = selectRegDrugsFilters(getState());
    const { take, skip } = selectRegDrugsPagination(getState());
    const { field, order } = selectRegDrugsOrderBy(getState());

    const params = {
      filter: makeFilters(filters),
      select: SELECT,
      orderBy: dynamic.orderBy(field, order),
      count: false,
      take: ignorePagination ? undefined : take,
      skip: ignorePagination ? undefined : skip,
    };
    const { data: result } = await ServiceRegDrugs.getAllDynamic<IGridRegDrug, typeof params>(
      params,
    );

    return result.value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionRegDrugsChangeItem = createAsyncThunk<
  void,
  Partial<IRegDrug>,
  AppAsyncThunkConfig
>(`${NAMESPACE}/actionRegDrugsChangeItem`, async (data, { dispatch }) => {
  try {
    const { id } = data;

    if (!id) {
      throw new Error('id is requiReg');
    }

    await dispatch(
      apiRegDrugs.endpoints.patchRegDrug.initiate({
        ...data,
        id: id,
      }),
    ).unwrap();
    await dispatch(actionRegDrugsGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
