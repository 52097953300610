import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCustomers, ICustomer, ServiceCustomers } from 'services/customers';
import { AppAsyncThunkConfig } from 'store';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { IGridCustomers, NAMESPACE, SELECT } from './helpers';
import {
  selectCustomersFilters,
  selectCustomersOrderBy,
  selectCustomersPagination,
} from './selectors';

const makeFilters = (filters: ReturnType<typeof selectCustomersFilters>) => {
  const { isActiveOnly, companyNumber, search, customerTypeID } = filters;
  return dynamic
    .mergeFilters(
      isActiveOnly && dynamic.makeFilter(['isActive'], true, dynamic.equals),
      dynamic.makeFilter(
        ['customerTypeID'],
        customerTypeID,
        dynamic.decoratorIsNotNullable(dynamic.equals),
      ),
      dynamic.makeFilter(['companyNumber'], companyNumber, dynamic.contains),
      dynamic.makeFilter(
        ['companyName', 'email', 'phone1', 'phone2', 'address', 'remarks'],
        search,
        dynamic.contains,
      ),
    )
    .join('&&');
};

export const actionCustomersGetAllDynamic = createAsyncThunk<
  { value: IGridCustomers[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`${NAMESPACE}/actionCustomersGetAllDynamic`, async (_, { getState }) => {
  try {
    const filters = selectCustomersFilters(getState());
    const { take, skip } = selectCustomersPagination(getState());
    const { field, order } = selectCustomersOrderBy(getState());

    const params = {
      filter: makeFilters(filters),
      select: SELECT,
      orderBy: dynamic.orderBy(field, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceCustomers.getAllDynamic<IGridCustomers, typeof params>(
      params,
    );

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCustomersGetExportData = createAsyncThunk<
  IGridCustomers[],
  { ignorePagination: boolean },
  AppAsyncThunkConfig
>(`${NAMESPACE}/actionCustomersGetExportData`, async ({ ignorePagination }, { getState }) => {
  try {
    const filters = selectCustomersFilters(getState());
    const { take, skip } = selectCustomersPagination(getState());
    const { field, order } = selectCustomersOrderBy(getState());

    const params = {
      filter: makeFilters(filters),
      select: SELECT,
      orderBy: dynamic.orderBy(field, order),
      count: false,
      take: ignorePagination ? undefined : take,
      skip: ignorePagination ? undefined : skip,
    };
    const { data: result } = await ServiceCustomers.getAllDynamic<IGridCustomers, typeof params>(
      params,
    );

    return result.value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCustomersChangeItem = createAsyncThunk<
  void,
  Partial<ICustomer>,
  AppAsyncThunkConfig
>(`${NAMESPACE}/actionCustomersChangeItem`, async (data, { dispatch }) => {
  try {
    const { id } = data;

    if (!id) {
      throw new Error('id is required');
    }

    await dispatch(
      apiCustomers.endpoints.patchCustomer.initiate({
        ...data,
        id: id,
      }),
    ).unwrap();
    await dispatch(actionCustomersGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
