import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_NATIONALITIES, IGridNationality, Nationality } from './models';

const REVALIDATE_KEY = 'Nationalities' as const;

export * from './models';

class Service extends DynamicService<Nationality> {}

export interface IGridNationalitiesParams {
  search: string;
  isActiveOnly: boolean;
  order: dynamic.DynamicOrder;
  take: number;
  skip: number;
}

type ApiModel = Nationality;

export const ServiceNationalities = new Service({
  getAll: API_NATIONALITIES.GET_ALL_DYNAMIC,
  post: API_NATIONALITIES.POST,
  patch: API_NATIONALITIES.PATCH,
  delete: API_NATIONALITIES.DELETE,
});

export const apiNationalities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridNationalities: build.query<
      DynamicResult<IGridNationality, { count: true }>,
      IGridNationalitiesParams
    >({
      queryFn: async ({ search, isActiveOnly, order, take, skip }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('title', search, dynamic.contains),
                isActiveOnly && dynamic.makeFilter('isActive', true, dynamic.equals),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'rowIndex',
              'title',
              'userPatientProfiles.count() as numberOfPharmacists',
              'isActive',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceNationalities.getAllDynamic<
            IGridNationality,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getNationality: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive'),
          };
          const { data } = await ServiceNationalities.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getNationalitiesOption: build.query<ApiModel[], void>({
      query: () => ({
        url: API_NATIONALITIES.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(dynamic.makeFilter(['isActive'], true, dynamic.equals))
            .join('&&'),
          select: dynamic.select('id', 'title'),
          orderBy: 'title',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    postNationality: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceNationalities.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchNationality: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceNationalities.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteNationality: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceNationalities.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
