import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserEmployeeProfiles,
  ServiceUserEmployeeProfiles,
  UserEmployeeProfile,
} from 'services/user-employee-profiles';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import {
  selectUserEmployeesFilters,
  selectUserEmployeesOrderBy,
  selectUserEmployeesPagination,
} from './selectors';
import { IGridUserEmployee } from './slice';

export const actionUserEmployeesGetAllDynamic = createAsyncThunk<
  { value: IGridUserEmployee[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`USER_EMPLOYEES/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { search, userEmployeeProfilePermissionID } = selectUserEmployeesFilters(getState());
    const { take, skip } = selectUserEmployeesPagination(getState());
    const { field, order } = selectUserEmployeesOrderBy(getState());
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter(
            ['firstName', 'lastName', 'email', 'mobilePhone'],
            search,
            dynamic.decoratorIsNotNullable(dynamic.contains),
          ),
          dynamic.makeFilter(
            'userEmployeeProfilePermissionID',
            userEmployeeProfilePermissionID,
            dynamic.decoratorIsNotNullable(dynamic.equals),
          ),
        )
        .join('&&'),
      select: dynamic.select(
        'id',
        'appIdentityUserID',
        'firstName',
        'lastName',
        'userEmployeeProfilePermissionID',
        'email',
        'mobilePhone',
        'isActive',
        'userCrmProfilePermission.title as position',
      ),
      orderBy: dynamic.orderBy(field, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceUserEmployeeProfiles.getAllDynamic<
      IGridUserEmployee,
      typeof params
    >(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionUserEmployeesChangeItem = createAsyncThunk<
  void,
  Partial<UserEmployeeProfile>,
  AppAsyncThunkConfig
>(`USER_EMPLOYEES/changeItem`, async (data, { dispatch }) => {
  try {
    const appIdentityUserID = data.appIdentityUserID;
    if (!appIdentityUserID) {
      throw new Error('appIdentityUserID is required');
    }
    await dispatch(
      apiUserEmployeeProfiles.endpoints.patchUserCrmProfile.initiate({
        ...data,
        appIdentityUserID,
      }),
    ).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
