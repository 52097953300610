import { LABEL_SOURCE_KEYS } from 'services/label-sources';
import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_LABELS, GridLabel, GridLabelsInput, ILabel } from './models';

const REVALIDATE_KEY = 'Labels' as const;

export * from './models';

class Service extends DynamicService<ILabel> {}

type ApiModel = ILabel;

export const ServiceLabels = new Service({
  mainField: 'id',
  getAll: API_LABELS.GET_ALL_DYNAMIC,
  post: API_LABELS.POST,
  patch: API_LABELS.PATCH,
  delete: API_LABELS.DELETE,
});

export const apiLabels = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridLabels: build.query<DynamicResult<GridLabel, { count: true }>, GridLabelsInput>({
      queryFn: async ({ languageID, search, orderBy, take, skip, labelSourceID }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['title', 'labelKey'], search, dynamic.contains),
                dynamic.makeFilter(
                  'languageID',
                  languageID,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
                dynamic.makeFilter('labelSourceID', labelSourceID, dynamic.equals),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'title',
              'labelKey',
              'language.title as languageTitle',
              'isActive',
            ),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            take,
            skip,
          };
          const { data } = await ServiceLabels.getAllDynamic<GridLabel, typeof params>(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getAppLabels: build.query<ApiModel[], string>({
      queryFn: async (languageID) => {
        try {
          const {
            data: { value },
          } = await ServiceLabels.getAllDynamic({
            select: dynamic.select('id', 'labelKey', 'title', 'labelSourceID'),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('languageID', languageID, dynamic.equals),
                dynamic.makeFilter('labelSource.key', LABEL_SOURCE_KEYS.CRM, dynamic.equals),
                dynamic.makeFilter('isActive', true, dynamic.equals),
              )
              .join('&&'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getLabel: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceLabels.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postLabel: build.mutation<void, Partial<ApiModel>>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchLabel: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteLabel: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
