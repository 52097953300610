import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  SagaGenerator,
  select,
  takeEvery,
} from 'typed-redux-saga';
import { convertToDate } from 'utils/dates';
import { selectCustomersBlockInvoices } from './selectors';
import { actionsCustomers } from './slice';

function* sagaBlockTimer(): SagaGenerator<any> {
  const initInvoices = yield* select(selectCustomersBlockInvoices);

  yield* all(
    initInvoices.map((item) => {
      if (new Date().getTime() > convertToDate(item.endDate).getTime()) {
        return put(actionsCustomers.unBlock(item));
      }
      return null;
    }),
  );

  const newInvoices = yield* select(selectCustomersBlockInvoices);

  if (newInvoices.length > 0) {
    yield* delay(1000);
    yield* call(sagaBlockTimer);
  }

  yield* cancel();
}

function* sagaBlocking() {
  let task = yield* fork(sagaBlockTimer);

  yield* takeEvery([actionsCustomers.block, actionsCustomers.unBlock], function* watcher() {
    if (task && task.isRunning()) return;
    task = yield* fork(sagaBlockTimer);
  });
}

export const sagasCustomers = [sagaBlocking()];
