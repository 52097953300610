import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { PersistMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';
import { apiRtk } from 'utils/service';
import packages from '../../package.json';
import { reducerAccount, sagasAccounts } from './auth';
import { reducerCustomers, sagasCustomers } from './customers';
import { reducerEmployeePermissions } from './employee-permissions';
import { reducerRegDrugs, sagasRegDrugs } from './reg-drugs';
import { reducerUserEmployees, sagasUserEmployees } from './user-employees';
import {
  reducerUserPharmacistProfiles,
  sagasUserPharmacistProfiles,
} from './user-pharmacist-profiles';

const VERSION = parseInt(packages.version.replace(/\./gi, ''));

const migrateStore: PersistMigrate = (state) => {
  if (VERSION === state?._persist.version) {
    return Promise.resolve(state);
  } else {
    return Promise.resolve(undefined);
  }
};

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  account: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'account',
      storage,
      whitelist: ['languageID'],
    },
    reducerAccount,
  ),
  userEmployees: reducerUserEmployees,
  employeePermissions: reducerEmployeePermissions,
  regDrugs: reducerRegDrugs,
  userPharmacistProfiles: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'userPharmacistProfiles',
      storage,
      whitelist: ['blockInvoices', 'blockInvoicesDetails'],
    },
    reducerUserPharmacistProfiles,
  ),

  customers: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'customers',
      storage,
      whitelist: ['blockInvoices', 'blockInvoicesDetails'],
    },
    reducerCustomers,
  ),

  [apiRtk.reducerPath]: apiRtk.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(sagaMiddleware)
      .concat(apiRtk.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

function* rootSaga() {
  yield all([
    ...sagasAccounts,
    ...sagasUserEmployees,
    ...sagasUserPharmacistProfiles,
    ...sagasCustomers,
    ...sagasRegDrugs,
  ]);
}

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};
