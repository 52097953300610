import * as dynamic from 'utils/dynamic';
import {
  apiRtk,
  decoratorWithFiles,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_TEAM_SUBJECTS,
  IGridTeamSubject,
  IGridTeamSubjectInput,
  ITeamSubject,
  TeamSubjectSourceModel,
} from './models';

const REVALIDATE_KEY = 'TeamSubjects' as const;

export * from './models';

type ApiModel = ITeamSubject;

class Service extends DynamicService<ApiModel> {
  @decoratorWithFiles('id', 'banner')
  async post(data: Partial<ApiModel>) {
    return super.post(data);
  }

  @decoratorWithFiles('id', 'banner')
  async patch(data: Partial<ApiModel>) {
    return super.patch(data);
  }
}

export const ServiceTeamSubjects = new Service({
  mainField: 'id',
  getAll: API_TEAM_SUBJECTS.GET_ALL_DYNAMIC,
  post: API_TEAM_SUBJECTS.POST,
  patch: API_TEAM_SUBJECTS.PATCH,
  delete: API_TEAM_SUBJECTS.DELETE,
});

export const apiTeamSubjects = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceTeamSubject: build.query<Array<TeamSubjectSourceModel>, void>({
      queryFn: async () => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive'),
          };
          const response = await ServiceTeamSubjects.getAllDynamic(params);
          const data = transformResponseDynamic(response.data);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY }],
    }),
    getGridTeamSubjects: build.query<
      DynamicResult<IGridTeamSubject, { count: true }>,
      IGridTeamSubjectInput
    >({
      queryFn: async ({ search, order, take, skip, isActiveOnly }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('title', search, dynamic.contains),
                isActiveOnly && dynamic.makeFilter('isActive', true, dynamic.equals),
              )
              .join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceTeamSubjects.getAllDynamic<IGridTeamSubject, typeof params>(
            params,
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getTeamSubject: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceTeamSubjects.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postTeamSubject: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceTeamSubjects.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchTeamSubject: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceTeamSubjects.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(id) },
      ],
    }),
    deleteTeamSubject: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceTeamSubjects.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(id) },
      ],
    }),
  }),
});
