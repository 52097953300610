import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.regDrugs;

export const selectRegDrugsStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);
export const selectRegDrugsData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectRegDrugsFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectRegDrugsPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
export const selectRegDrugsOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
