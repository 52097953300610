import { ICustomer } from 'services/customers';
import * as dynamic from 'utils/dynamic';

export const NAMESPACE = 'CUSTOMERS';
export const SELECT = dynamic.select(
  'id',
  'companyNumber',
  'companyName',
  'companyLogo',
  'isActive',
  'email',
  'phone1',
  'phone2',
  'address',
  'remarks',
  'customerType.title as customerTypeTitle',
);

export interface IGridCustomers
  extends Pick<
    ICustomer,
    | 'id'
    | 'companyName'
    | 'companyNumber'
    | 'companyLogo'
    | 'isActive'
    | 'email'
    | 'phone1'
    | 'phone2'
    | 'address'
    | 'remarks'
  > {
  customerTypeTitle: string;
}
