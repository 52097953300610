import { useMemo } from 'react';
import { apiArticleSubjects } from 'services/article-subjects';
import { apiCities } from 'services/cities';
import { apiConsultRequestTypes } from 'services/consult-request-types';
import { apiConsultSessionMedications } from 'services/consult-session-medications';
import { apiConsultSessions } from 'services/consult-sessions';
import { apiGenders } from 'services/genders';
import { apiIncomeAccounts } from 'services/income-accounts';
import { apiInsuranceStatuses } from 'services/insurance-statuses';
import { apiInvoiceTypes } from 'services/invoice-types';
import { apiLanguages } from 'services/languages';
import { apiLogisticClassifications } from 'services/logistic-classifications';
import { apiMedicalStaffOptions } from 'services/medical-staff-options';
import { apiMedicalStaffWorkPlaces } from 'services/medical-staff-work-places';
import { apiMemberTypes } from 'services/member-types';
import { apiNamePrefixes } from 'services/name-prefixes';
import { apiNamePrefixesEng } from 'services/name-prefixes-eng';
import { apiNationalities } from 'services/nationalities';
import { apiPaymentAccounts } from 'services/payment-accounts';
import { apiPaymentTypeSubjects } from 'services/payment-type-subjects';
import { apiPaymentTypes } from 'services/payment-types';
import { apiReferenceSources } from 'services/reference-sources';
import { apiReferralTypes } from 'services/referral-types';
import { apiRegions } from 'services/regions';
import { apiResponsivenessToTreatments } from 'services/responsiveness-to-treatments';
import { apiRightsExtractionClassifications } from 'services/rights-extraction-classifications';
import { apiSuppliers } from 'services/suppliers';
import { apiUserEmployeeProfilePermissions } from 'services/user-employee-profile-permissions';
import { apiUserEmployeeProfiles } from 'services/user-employee-profiles';
import { apiUserPharmacistProfileContacts } from 'services/user-pharmacist-profile-contacts';
import { apiUserPharmacistProfilePayments } from 'services/user-pharmacist-profile-invoice-details';
import { apiUserPharmacistProfileInvoices } from 'services/user-pharmacist-profile-invoices';
import { apiUserPharmacistProfiles } from 'services/user-pharmacist-profiles';
import { apiWorkPlaceTypes } from 'services/work-place-types';
import { apiWorkPlaces } from 'services/work-places';
import { ArrayType } from 'utils/types';
import { apiCustomerContacts } from '../services/customer-contacts';
import { apiCustomerTypes } from '../services/customer-types';
import { apiCustomers } from '../services/customers';
import { apiTeamSubjects } from '../services/team-subjects';
import { useMap } from './use-map';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

export const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return useMemo(() => ({ ...v, data }), [v, data]);
};
export const useSourceDataMap = <T extends { data: any[] }>(
  v: T,
  mapKey: keyof ArrayType<T['data']>,
) => {
  const map = useMap<ArrayType<T['data']>>(v.data, String(mapKey));
  return useMemo(() => ({ ...v, map }), [v, map]);
};
export const useSourceEmployeePermissions = () => {
  return useSourceData(
    apiUserEmployeeProfilePermissions.useGetUserEmployeeProfilePermissionSourceQuery(),
  );
};
export const useSourceLanguages = () => {
  return useSourceData(apiLanguages.useGetAllLanguagesQuery());
};
export const useSourceCities = () => {
  return useSourceData(apiCities.useGetCitiesOptionQuery());
};
export const useSourceRegions = () => {
  return useSourceData(apiRegions.useGetRegionsOptionQuery());
};
export const useSourceNationalities = () => {
  return useSourceData(apiNationalities.useGetNationalitiesOptionQuery());
};
export const useSourceMemberTypes = () => {
  return useSourceData(apiMemberTypes.useGetMemberTypesOptionQuery());
};
export const useSourceWorkPlaces = () => {
  return useSourceData(apiWorkPlaces.useGetWorkPlacesOptionQuery());
};
export const useSourceNamePrefixes = () => {
  return useSourceData(apiNamePrefixes.useGetNamePrefixesOptionQuery());
};
export const useSourceNamePrefixesEng = () => {
  return useSourceData(apiNamePrefixesEng.useGetNamePrefixesEngOptionQuery());
};
export const useSourceWorkPlaceTypes = () => {
  return useSourceData(apiWorkPlaceTypes.useGetWorkPlaceTypesOptionQuery());
};
export const useSourcePaymentTypes = ({
  isFilteredByRowIndex = false,
}: {
  isFilteredByRowIndex?: boolean;
} = {}) => {
  return useSourceData(apiPaymentTypes.useGetSourcePaymentTypesQuery({ isFilteredByRowIndex }));
};
export const useSourcePaymentTypeSubjects = () => {
  return useSourceData(apiPaymentTypeSubjects.useGetSourcePaymentTypeSubjectsQuery());
};
export const useSourceSuppliers = () => {
  return useSourceData(apiSuppliers.useGetSuppliersOptionQuery());
};
export const useSourceUserPharmacistProfilesName = (options: { skip: boolean }) => {
  return useSourceData(
    apiUserPharmacistProfiles.useGetUserPharmacistProfilesNameQuery(undefined, options),
  );
};
export const useSourceIncomeAccounts = () => {
  return useSourceData(apiIncomeAccounts.useGetSourceIncomeAccountsQuery());
};
export const useSourcePaymentAccounts = () => {
  return useSourceData(apiPaymentAccounts.useGetSourcePaymentAccountsQuery());
};
export const useSourceGenders = () => {
  return useSourceData(apiGenders.useGetGendersOptionQuery());
};
export const useSourceInvoiceTypes = () => {
  return useSourceData(apiInvoiceTypes.useGetInvoiceTypesOptionQuery());
};
export const useSourceAvailableInvoiceNumbers = ({ isActiveOnly }: { isActiveOnly: boolean }) => {
  return useSourceData(
    apiUserPharmacistProfilePayments.useGetAvailableInvoiceNumbersSourceQuery({ isActiveOnly }),
  );
};
export const useSourceAvailableInvoiceNumbersForOpenInvoices = ({
  isActiveOnly,
}: {
  isActiveOnly: boolean;
}) => {
  return useSourceData(
    apiUserPharmacistProfileInvoices.useGetAvailableOpenInvoicesInvoiceNumbersSourceQuery({
      isActiveOnly,
    }),
  );
};
export const useSourceUserContacts = (options: {
  skip: boolean;
  userPharmacistProfileID: string;
}) => {
  return useSourceData(
    apiUserPharmacistProfileContacts.useGetUserPharmacistProfileContactsEmailQuery(
      { userPharmacistProfileID: options?.userPharmacistProfileID || '' },
      options,
    ),
  );
};

export const useSourceCustomerContacts = (options: { customerID: string }) => {
  return useSourceData(
    apiCustomerContacts.useGetCustomerContactsByCustomerQuery({ customerID: options.customerID }),
  );
};

export const useSourceConsultRequestTypes = () => {
  return useSourceData(apiConsultRequestTypes.useGetSourceConsultRequestTypesQuery());
};

export const useSourceReferralTypes = () => {
  return useSourceData(apiReferralTypes.useGetSourceReferralTypesQuery());
};

export const useSourceConsultSessionReferralTypeOptionOther = () => {
  return useSourceData(apiConsultSessions.useGetSourceConsultSessionReferralTypeOptionOtherQuery());
};

export const useSourceInsuranceStatuses = () => {
  return useSourceData(apiInsuranceStatuses.useGetSourceInsuranceStatusesQuery());
};

export const useSourceConsultSessionInsuranceStatusOptionOther = () => {
  return useSourceData(
    apiConsultSessions.useGetSourceConsultSessionInsuranceStatusOptionOtherQuery(),
  );
};

export const useSourceMedicalStaffOptions = () => {
  return useSourceData(apiMedicalStaffOptions.useGetSourceMedicalStaffOptionsQuery());
};

export const useSourceConsultSessionMedicalStaffOptionOther = () => {
  return useSourceData(apiConsultSessions.useGetSourceConsultSessionMedicalStaffOptionOtherQuery());
};

export const useSourceMedicalStaffWorkPlaces = () => {
  return useSourceData(apiMedicalStaffWorkPlaces.useGetSourceMedicalStaffWorkPlacesQuery());
};

export const useSourceConsultSessionMedicalStaffWorkPlaceOptionOther = () => {
  return useSourceData(
    apiConsultSessions.useGetSourceConsultSessionMedicalStaffWorkPlaceOptionOtherQuery(),
  );
};

export const useSourceReferenceSources = () => {
  return useSourceData(apiReferenceSources.useGetSourceReferenceSourcesQuery());
};

export const useSourceConsultSessionReferenceSourcesOptionOther = () => {
  return useSourceData(
    apiConsultSessions.useGetSourceConsultSessionReferenceSourceOptionOtherQuery(),
  );
};

export const useSourceConsultSessionAnsweredOptionOther = () => {
  return useSourceData(apiConsultSessions.useGetSourceConsultSessionAnsweredOptionOtherQuery());
};

export const useSourceUserEmployeeProfiles = ({
  appIdentityUserID = false,
}: {
  appIdentityUserID?: string | boolean;
} = {}) => {
  return useSourceData(
    apiUserEmployeeProfiles.useGetUserCrmProfileSourceQuery({ appIdentityUserID }),
  );
};

export const useSourceResponsivenessToTreatments = () => {
  return useSourceData(apiResponsivenessToTreatments.useGetSourceResponsivenessToTreatmentsQuery());
};

export const useSourceLogisticClassifications = () => {
  return useSourceData(apiLogisticClassifications.useGetSourceLogisticClassificationsQuery());
};

export const useSourceRightsExtractionClassifications = () => {
  return useSourceData(
    apiRightsExtractionClassifications.useGetSourceRightsExtractionClassificationsQuery(),
  );
};

export const useSourceConsultSessionMedication = () => {
  return useSourceData(apiConsultSessionMedications.useGetSourceConsultSessionMedicationQuery());
};

export const useSourceArticleSubjects = () => {
  return useSourceData(apiArticleSubjects.useGetSourceArticleSubjectQuery());
};
export const useSourceTeamSubjects = () => {
  return useSourceData(apiTeamSubjects.useGetSourceTeamSubjectQuery());
};
export const useSourceCustomerTypes = () => {
  return useSourceData(apiCustomerTypes.useGetSourceCustomerTypesQuery());
};
export const useSourceCustomers = () => {
  return useSourceData(apiCustomers.useGetSourceCustomersQuery());
};
