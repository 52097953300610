import * as yup from 'yup';
import { DynamicOrder } from '../../utils/dynamic';

const API = 'ArticleSubjects';

export const API_ARTICLE_SUBJECTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IArticleSubject, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IArticleSubject, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IArticleSubject extends Components.Schemas.ArticleSubject {
  id: string;
}

export const schemaArticleSubject = yup.object({
  title: yup.string().required('rule-required').default(''),
  htmlContent: yup.string().nullable().required('rule-required').default(''),
  banner: yup.mixed().nullable().notRequired().default(''),
  metaTitle: yup.string().nullable().notRequired().trim().default(''),
  metaDescription: yup.string().nullable().notRequired().trim().default(''),
  metaKeyWords: yup.string().nullable().notRequired().trim().default(''),
  isActive: yup.boolean().default(true),
});

export type ArticleSourceModel = Pick<IArticleSubject, 'id' | 'title' | 'isActive'>;

export interface IGridArticleSubject
  extends Pick<IArticleSubject, 'id' | 'title' | 'isActive' | 'rowIndex'> {}

export interface IGridArticleSubjectInput {
  search: string;
  isActiveOnly: boolean;
  take: number;
  skip: number;
  order: DynamicOrder;
}
