import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserPharmacistProfiles,
  ServiceUserPharmacistProfiles,
  UserPharmacistProfile,
} from 'services/user-pharmacist-profiles';
import { AppAsyncThunkConfig } from 'store';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import {
  selectUserPharmacistProfilesFilters,
  selectUserPharmacistProfilesOrderBy,
  selectUserPharmacistProfilesPagination,
} from './selectors';
import { IGridUserPharmacistProfiles } from './slice';

export const actionUserPharmacistProfilesGetAllDynamic = createAsyncThunk<
  { value: IGridUserPharmacistProfiles[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`USER_PHARMACIST_PROFILES/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { firstName, lastName, email, idNumber, mobilePhone, isActiveOnly } =
      selectUserPharmacistProfilesFilters(getState());
    const { take, skip } = selectUserPharmacistProfilesPagination(getState());
    const { field, order } = selectUserPharmacistProfilesOrderBy(getState());

    const params = {
      filter: dynamic
        .mergeFilters(
          isActiveOnly && dynamic.makeFilter(['firstName', 'lastName'], '', dynamic.notEquals),
          isActiveOnly && dynamic.makeFilter(['isActive'], true, dynamic.equals),
          dynamic.makeFilter(['firstName'], firstName, dynamic.contains),
          dynamic.makeFilter(['lastName'], lastName, dynamic.contains),
          dynamic.makeFilter(['email'], email, dynamic.contains),
          dynamic.makeFilter(['idNumber'], idNumber, dynamic.contains),
          dynamic.makeFilter(['phoneMobile'], mobilePhone, dynamic.contains),
        )
        .join('&&'),
      select: dynamic.select(
        'id',
        '(firstName + " " + lastName).trim() as fullName',
        'idNumber',
        'email',
        'appIdentityUserID',
        'phoneMobile',
        'city.title as cityTitle',
        'licenseNumber',
        'position',
        'workPlace.title as workPlaceTitle',
        'isActive',
      ),
      orderBy: dynamic.orderBy(field as any, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceUserPharmacistProfiles.getAllDynamic<
      IGridUserPharmacistProfiles,
      typeof params
    >(params);

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionUserPharmacistProfilesChangeItem = createAsyncThunk<
  void,
  Partial<UserPharmacistProfile>,
  AppAsyncThunkConfig
>(`USER_PHARMACIST_PROFILES/changeItem`, async (data, { dispatch }) => {
  try {
    const { appIdentityUserID } = data;

    if (!appIdentityUserID) {
      throw new Error('appIdentityUserID is required');
    }
    await dispatch(
      apiUserPharmacistProfiles.endpoints.patchUserPharmacistProfile.initiate({
        ...data,
        appIdentityUserID,
      }),
    );
    await dispatch(actionUserPharmacistProfilesGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
