import { ICustomer } from 'services/customers';
import { IRegDrug } from 'services/reg-drugs';
import * as dynamic from 'utils/dynamic';

export const NAMESPACE = 'REG_DRUGS';
export const SELECT = dynamic.select(
  'id',
  'publishDate',
  'title',
  'customerID',
  'new { customer.companyName, customer.companyLogo } as customer',
);

export interface IGridRegDrug
  extends Pick<IRegDrug, 'id' | 'publishDate' | 'title' | 'customerID'> {
  customer: Pick<ICustomer, 'companyName' | 'companyLogo'>;
}
