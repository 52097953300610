import { createSelector } from '@reduxjs/toolkit';
import { addSeconds } from 'date-fns';
import {
  TIME_CREATING_IMPORT_FROM_EXCEL_SECONDS,
  TIME_CREATING_INVOICE_PAYMENT_SECONDS,
} from 'services/cardcom';
import { convertToDate } from 'utils/dates';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.customers;

export const selectCustomersStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);
export const selectCustomersData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectCustomersBlockInvoices = createSelector(selectState, ({ blockInvoices }) => {
  return blockInvoices.map((item) => ({
    ...item,
    endDate: addSeconds(convertToDate(item.startDate), TIME_CREATING_INVOICE_PAYMENT_SECONDS),
  }));
});
export const selectCustomersBlockInvoicesDetails = createSelector(
  selectState,
  ({ blockInvoicesDetails }) => {
    return blockInvoicesDetails.map((item) => ({
      ...item,
      endDate: addSeconds(convertToDate(item.startDate), TIME_CREATING_IMPORT_FROM_EXCEL_SECONDS),
    }));
  },
);
export const selectCustomersFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectCustomersPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
export const selectCustomersOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
