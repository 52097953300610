import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';

const API = 'TeamSubjects';

export const API_TEAM_SUBJECTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITeamSubject, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITeamSubject, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ITeamSubject extends Components.Schemas.TeamSubject {
  id: string;
}

export const schemaTeamSubject = yup.object({
  title: yup.string().required('rule-required').default(''),
  htmlContent: yup.string().nullable().required('rule-required').default(''),
  banner: yup.mixed().nullable().notRequired().default(''),
  metaTitle: yup.string().nullable().notRequired().trim().default(''),
  metaDescription: yup.string().nullable().notRequired().trim().default(''),
  metaKeyWords: yup.string().nullable().notRequired().trim().default(''),
  isActive: yup.boolean().default(true),
});

export type TeamSubjectSourceModel = Pick<ITeamSubject, 'id' | 'title' | 'isActive'>;

export interface IGridTeamSubject
  extends Pick<ITeamSubject, 'id' | 'title' | 'isActive' | 'rowIndex'> {}

export interface IGridTeamSubjectInput {
  search: string;
  isActiveOnly: boolean;
  take: number;
  skip: number;
  order: DynamicOrder;
}
