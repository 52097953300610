import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addSeconds } from 'date-fns';
import { TIME_CREATING_IMPORT_FROM_EXCEL_SECONDS } from 'services/cardcom';
import { actionAccountLogout } from 'store/auth';
import { convertToDate, DateValue } from 'utils/dates';
import { DynamicOrder } from 'utils/dynamic';
import { actionCustomersGetAllDynamic } from './actions';
import { IGridCustomers, NAMESPACE } from './helpers';

interface Filters {
  search: string;
  companyNumber: string;
  customerTypeID: string;
  isActiveOnly: boolean;
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}

interface BlockInvoice {
  customerID: string;
  startDate: DateValue;
}
interface BlockInvoiceDetail {
  customerInvoiceID: string;
  startDate: DateValue;
  endDate?: DateValue;
  requestCount: number;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridCustomers[];
  filters: Filters;
  pagination: Pagination;
  orderBy: DynamicOrder;
  dataProfileInvoiceDetailsLength: number;
  blockInvoices: BlockInvoice[];
  blockInvoicesDetails: BlockInvoiceDetail[];
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    dataProfileInvoiceDetailsLength: 0,
    isInit: false,
    data: [],
    filters: {
      search: '',
      companyNumber: '',
      customerTypeID: '',
      isActiveOnly: true,
    },
    pagination: {
      take: 25,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'companyName',
      order: 'asc',
    },
    blockInvoices: [],
    blockInvoicesDetails: [],
  };
};

const slice = createSlice({
  name: NAMESPACE,
  initialState: initState(),
  reducers: {
    filtersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    filtersReset(state) {
      state.filters = initState().filters;
    },
    orderBySet(state, action: PayloadAction<Partial<DynamicOrder>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
    detailsLengthSet(state, action: PayloadAction<number>) {
      state.dataProfileInvoiceDetailsLength = action.payload;
    },

    block(state, action: PayloadAction<BlockInvoice>) {
      state.blockInvoices = [...state.blockInvoices, action.payload];
    },
    unBlock(state, action: PayloadAction<Pick<BlockInvoice, 'customerID'>>) {
      state.blockInvoices = state.blockInvoices.filter(
        (item) => item.customerID !== action.payload.customerID,
      );
    },
    detailsBlock(state, action: PayloadAction<BlockInvoiceDetail>) {
      state.blockInvoicesDetails = [...state.blockInvoicesDetails, action.payload];
    },
    invoicesDetailsBlock(state, action: PayloadAction<BlockInvoiceDetail>) {
      state.blockInvoicesDetails.push(action.payload);
    },
    invoicesDetailsUpdate(
      state,
      action: PayloadAction<Pick<BlockInvoiceDetail, 'customerInvoiceID'>>,
    ) {
      const foundIndex = state.blockInvoicesDetails.findIndex(
        (item) => item.customerInvoiceID === action.payload.customerInvoiceID,
      );

      if (foundIndex >= 0) {
        const item = state.blockInvoicesDetails[foundIndex];

        item.startDate = new Date();
        item.endDate = addSeconds(
          convertToDate(new Date()),
          TIME_CREATING_IMPORT_FROM_EXCEL_SECONDS,
        );
        item.requestCount++;
      }
    },
    invoicesDetailsUnBlock(
      state,
      action: PayloadAction<Pick<BlockInvoiceDetail, 'customerInvoiceID'>>,
    ) {
      state.blockInvoicesDetails = state.blockInvoicesDetails.filter(
        (item) => item.customerInvoiceID !== action.payload.customerInvoiceID,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionCustomersGetAllDynamic.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
        state.pagination.take = action.meta.arg?.take || state.pagination.take;
      })
      .addCase(actionCustomersGetAllDynamic.fulfilled, (state, action) => {
        const {
          payload: { value, count },
        } = action;
        state.isLoading = false;
        state.isInit = true;
        state.data = value;
        state.pagination.count = count;
      })
      .addCase(actionCustomersGetAllDynamic.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
  },
});

export const actionsCustomers = slice.actions;
export const reducerCustomers = slice.reducer;
