import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_PAYMENT_TYPES,
  IDashboardPaymentLines,
  IDashboardPaymentLinesArgs,
  IGridPaymentType,
  IGridPaymentTypeArgs,
  IPaymentType,
  PaymentType,
} from './models';

const REVALIDATE_KEY = 'PaymentTypes' as const;

export * from './models';

class Service extends DynamicService<PaymentType> {}

type ApiModel = PaymentType;

export const ServicePaymentTypes = new Service({
  getAll: API_PAYMENT_TYPES.GET_ALL_DYNAMIC,
  post: API_PAYMENT_TYPES.POST,
  patch: API_PAYMENT_TYPES.PATCH,
  delete: API_PAYMENT_TYPES.DELETE,
});

interface SourceModel extends Pick<IPaymentType, 'id' | 'title' | 'defaultAmount' | 'showRemarks'> {
  typeSubjectIndex: number;
}
export const apiPaymentTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourcePaymentTypes: build.query<SourceModel[], { isFilteredByRowIndex?: boolean }>({
      query: ({ isFilteredByRowIndex }) => ({
        url: API_PAYMENT_TYPES.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select(
            'id',
            'title',
            'defaultAmount',
            'showRemarks',
            'paymentTypeSubject.rowIndex as typeSubjectIndex',
          ),
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter('isActive', true, dynamic.equals),
              isFilteredByRowIndex &&
                dynamic.makeFilter('paymentTypeSubject.rowIndex', 2, dynamic.equals),
            )
            .join('&&'),
          orderBy: dynamic.orderBy('title', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getGridPaymentTypes: build.query<
      DynamicResult<IGridPaymentType, { count: true }>,
      IGridPaymentTypeArgs
    >({
      queryFn: async ({ search, order, take, skip, isActiveOnly }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['title', 'paymentTypeSubject.title'], search, dynamic.contains),
                isActiveOnly && dynamic.makeFilter('isActive', true, dynamic.equals),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'rowIndex',
              'title',
              'isActive',
              'defaultAmount',
              'paymentTypeSubject.title as paymentTypeSubjectTitle',
              'showRemarks',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServicePaymentTypes.getAllDynamic<IGridPaymentType, typeof params>(
            params,
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getDashboardPaymentLines: build.query<IDashboardPaymentLines[], IDashboardPaymentLinesArgs>({
      query: ({ search, orderBy }) => ({
        url: API_PAYMENT_TYPES.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select(
            'id',
            'title',
            'paymentTypeSubject.title as paymentTypeSubjectTitle',
          ),
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                ['title', 'paymentTypeSubject.title'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
              dynamic.makeFilter('isActive', true, dynamic.equals),
            )
            .join('&&'),
          orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getPaymentType: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'title',
              'isActive',
              'paymentTypeSubjectID',
              'showRemarks',
            ),
          };
          const { data } = await ServicePaymentTypes.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postPaymentType: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServicePaymentTypes.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchPaymentType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentTypes.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deletePaymentType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentTypes.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
