import { apiRtk, decoratorWithFiles, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_CUSTOMERS, ICustomer, SourceCustomer } from './models';
import { SELECT_SOURCE } from './queries';

const REVALIDATE_TAG = 'Customers' as const;

type ApiModel = ICustomer;

export * from './models';

class Service extends DynamicService<ApiModel> {
  @decoratorWithFiles('id', 'companyLogo')
  async post(data: Partial<ApiModel>) {
    return super.post(data);
  }

  @decoratorWithFiles('id', 'companyLogo')
  async patch(data: Partial<ApiModel>) {
    return super.patch(data);
  }
}

export const ServiceCustomers = new Service({
  mainField: 'id',
  getAll: API_CUSTOMERS.GET_ALL_DYNAMIC,
  post: API_CUSTOMERS.POST,
  patch: API_CUSTOMERS.PATCH,
  delete: API_CUSTOMERS.DELETE,
});

export const apiCustomers = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceCustomers: build.query<Array<SourceCustomer>, void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceCustomers.getAllDynamic<SourceCustomer>({
            select: SELECT_SOURCE,
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: () => [{ type: REVALIDATE_TAG }],
    }),
    getCustomer: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceCustomers.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postCustomer: build.mutation<void, Partial<ApiModel>>({
      queryFn: async (customer) => {
        try {
          await ServiceCustomers.post(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchCustomer: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceCustomers.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteCustomer: build.mutation<void, Partial<ApiModel>>({
      queryFn: async (data) => {
        try {
          await ServiceCustomers.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
  }),
});
