import * as dynamic from 'utils/dynamic';
import * as yup from 'yup';

const API = 'CustomerContacts';

export const API_CUSTOMER_CONTACTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ICustomerContact, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ICustomerContact, 'id'>) => `${API}/Delete/${data.id}`,
};
export const schemaCustomerContact = yup.object({
  customerID: yup.string().required('rule-required').trim().default(''),
  name: yup.string().required('rule-required').trim().default(''),
  email: yup.string().required('rule-required').email('rule-email').trim().default(''),
  department: yup.string().notRequired().nullable().trim().default(''),
  phone1: yup.string().notRequired().nullable().trim().default(''),
  phone2: yup.string().notRequired().nullable().trim().default(''),
  remarks: yup.string().notRequired().nullable().trim().default(''),
});

export interface ICustomerContact extends Components.Schemas.CustomerContact {
  id: string;
}
export interface IGridCustomerContact
  extends Pick<
    ICustomerContact,
    'id' | 'name' | 'department' | 'email' | 'phone1' | 'phone2' | 'remarks'
  > {}

export type CustomerContactEmail = Pick<
  ICustomerContact,
  'id' | 'name' | 'department' | 'email'
> & {
  email: string;
};
export interface IGridCustomerContactInput {
  search: string;
  take?: number;
  skip?: number;
  orderBy: dynamic.DynamicOrder;
  customerID: string;
}
