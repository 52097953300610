import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_CUSTOMER_CONTACTS,
  CustomerContactEmail,
  ICustomerContact,
  IGridCustomerContact,
  IGridCustomerContactInput,
} from './models';

type ApiModel = ICustomerContact;

class Service extends DynamicService<ApiModel> {}

const REVALIDATE_TAG = 'CustomerContacts' as const;

export * from './models';

export const ServiceCustomerContacts = new Service({
  getAll: API_CUSTOMER_CONTACTS.GET_ALL_DYNAMIC,
  post: API_CUSTOMER_CONTACTS.POST,
  patch: API_CUSTOMER_CONTACTS.PATCH,
  delete: API_CUSTOMER_CONTACTS.DELETE,
});

export const apiCustomerContacts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridCustomerContacts: build.query<
      DynamicResult<IGridCustomerContact, { count: true }>,
      IGridCustomerContactInput
    >({
      queryFn: async ({ orderBy, search, take, skip, customerID }) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'name',
              'department',
              'email',
              'phone1',
              'phone2',
              'remarks',
            ),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  ['name', 'department', 'email', 'phone1', 'phone2', 'remarks'],
                  search,
                  dynamic.contains,
                ),
                dynamic.makeFilter('customerID', customerID, dynamic.equals),
              )
              .join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            take,
            skip,
          };
          const { data } = await ServiceCustomerContacts.getAllDynamic<
            IGridCustomerContact,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getCustomerContact: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceCustomerContacts.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getCustomerContactsByCustomer: build.query<CustomerContactEmail[], { customerID: string }>({
      query: ({ customerID }) => ({
        url: API_CUSTOMER_CONTACTS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'name', 'department', 'email'),
          orderBy: 'name desc',
          filter: dynamic
            .mergeFilters(dynamic.makeFilter('customerID', customerID, dynamic.equals))
            .join('&&'),
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    postCustomerContact: build.mutation<ApiModel, Partial<ApiModel>>({
      queryFn: async (customer) => {
        try {
          const result = await ServiceCustomerContacts.post(customer);
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchCustomerContact: build.mutation<
      PatchPartial<ApiModel, 'id'>,
      PatchPartial<ApiModel, 'id'>
    >({
      queryFn: async (customer) => {
        try {
          const result = await ServiceCustomerContacts.patch(customer);
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    deleteCustomerContact: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceCustomerContacts.delete(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
  }),
});
