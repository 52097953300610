import { BoxProps, SvgIconProps, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import React from 'react';
import { AppCircleProgress } from '../app-circle-progress';
import style from './index.module.scss';

interface Classes {
  root: string;
  icon: string;
}
interface Props extends BoxProps {
  IconProps?: SvgIconProps;
  classes?: Partial<Classes>;
  title?: string;
}
export const AppLoading: React.FC<Props> = ({
  IconProps,
  className,
  classes,
  title = '',
  ...rest
}) => {
  return (
    <Box
      className={clsx(style.root, className, classes?.root)}
      position={'absolute'}
      top={0}
      right={0}
      bottom={0}
      left={0}
      zIndex={1100}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      {...rest}
    >
      <Tooltip title={title} placement="top">
        <div>
          <AppCircleProgress
            fontSize={'large'}
            sx={{ fontSize: 100 }}
            {...IconProps}
            className={clsx(IconProps?.className, classes?.icon)}
          />
        </div>
      </Tooltip>
    </Box>
  );
};
