import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_REGIONS, IGridRegion, Region } from './models';

const REVALIDATE_KEY = 'Regions' as const;

export * from './models';

export interface IGridRegionsParams {
  search: string;
  isActiveOnly: boolean;
  order: dynamic.DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<Region> {}

type ApiModel = Region;

export const ServiceRegions = new Service({
  getAll: API_REGIONS.GET_ALL_DYNAMIC,
  post: API_REGIONS.POST,
  patch: API_REGIONS.PATCH,
  delete: API_REGIONS.DELETE,
});

export const apiRegions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridRegions: build.query<DynamicResult<IGridRegion, { count: true }>, IGridRegionsParams>({
      queryFn: async ({ search, isActiveOnly, order, take, skip }) => {
        try {
          const params = {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('title', search, dynamic.contains),
                isActiveOnly && dynamic.makeFilter('isActive', true, dynamic.equals),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'rowIndex',
              'title',
              'userPatientProfiles.count() as numberOfPharmacists',
              'isActive',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceRegions.getAllDynamic<IGridRegion, typeof params>(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getRegion: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive'),
          };
          const { data } = await ServiceRegions.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getRegionsOption: build.query<ApiModel[], void>({
      query: () => ({
        url: API_REGIONS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(dynamic.makeFilter(['isActive'], true, dynamic.equals))
            .join('&&'),
          select: dynamic.select('id', 'title'),
          orderBy: 'title',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    postRegion: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceRegions.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchRegion: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceRegions.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteRegion: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceRegions.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
