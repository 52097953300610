import { isDateLike } from 'utils/dates';
import * as yup from 'yup';

const API = 'RegDrugs';

export const API_REG_DRUGS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IRegDrug, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IRegDrug, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IRegDrug extends Components.Schemas.RegDrug {
  id: string;
}
export const schemaRegDrug = yup.object({
  customerID: yup.string().required('rule-required').default(''),
  title: yup.string().required('rule-required').default(''),
  publishDate: yup
    .string()
    .required('rule-required')
    .test('date', 'rule-date', isDateLike)
    .default(() => new Date().toISOString()),
  isActive: yup.boolean().default(true),
});
