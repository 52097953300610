import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionAccountLogout } from 'store/auth';
import { DynamicOrder } from 'utils/dynamic';
import { actionRegDrugsGetAllDynamic } from './actions';
import { IGridRegDrug, NAMESPACE } from './helpers';

interface Filters {
  search: string;
  customerID: string;
  dateRange: string[];
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridRegDrug[];
  filters: Filters;
  pagination: Pagination;
  orderBy: DynamicOrder;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    filters: {
      search: '',
      customerID: '',
      dateRange: [],
    },
    pagination: {
      take: 25,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'publishDate',
      order: 'asc',
    },
  };
};

const slice = createSlice({
  name: NAMESPACE,
  initialState: initState(),
  reducers: {
    filtersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    filtersReset(state) {
      state.filters = initState().filters;
    },
    orderBySet(state, action: PayloadAction<Partial<DynamicOrder>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionRegDrugsGetAllDynamic.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
        state.pagination.take = action.meta.arg?.take || state.pagination.take;
      })
      .addCase(actionRegDrugsGetAllDynamic.fulfilled, (state, action) => {
        const {
          payload: { value, count },
        } = action;
        state.isLoading = false;
        state.isInit = true;
        state.data = value;
        state.pagination.count = count;
      })
      .addCase(actionRegDrugsGetAllDynamic.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
  },
});

export const actionsRegDrugs = slice.actions;
export const reducerRegDrugs = slice.reducer;
