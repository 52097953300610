import { format } from 'date-fns';
import * as yup from 'yup';

const API = 'Customers';

export const API_CUSTOMERS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ICustomer, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ICustomer, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ICustomer extends Components.Schemas.Customer {
  id: string;
}
export const schemaCustomer = yup.object({
  customerTypeID: yup.string().required('rule-required').default(''),
  companyName: yup.string().required('rule-required').default(''),
  companyNumber: yup.string().required('rule-required').default(''),
  companyLogo: yup.mixed().nullable().notRequired().default(null),
  email: yup
    .string()
    .required('rule-required')
    .email('rule-email')
    .default(() => `${format(new Date(), 'yyyyMMddHHmm')}@email.com`),
  phone1: yup.string().notRequired().nullable().default(''),
  phone2: yup.string().notRequired().nullable().default(''),
  address: yup.string().notRequired().nullable().default(''),
  remarks: yup.string().notRequired().nullable().default(''),
});

export type SourceCustomer = Pick<
  ICustomer,
  'id' | 'companyName' | 'companyNumber' | 'companyLogo' | 'isActive'
>;
