import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_REG_DRUGS, IRegDrug } from './models';

const REVALIDATE_TAG = 'RegDrugs' as const;

type ApiModel = IRegDrug;

export * from './models';

class Service extends DynamicService<ApiModel> {}

export const ServiceRegDrugs = new Service({
  mainField: 'id',
  getAll: API_REG_DRUGS.GET_ALL_DYNAMIC,
  post: API_REG_DRUGS.POST,
  patch: API_REG_DRUGS.PATCH,
  delete: API_REG_DRUGS.DELETE,
});

export const apiRegDrugs = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getRegDrug: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceRegDrugs.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postRegDrug: build.mutation<void, Partial<ApiModel>>({
      queryFn: async (customer) => {
        try {
          await ServiceRegDrugs.post(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchRegDrug: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceRegDrugs.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteRegDrug: build.mutation<void, Partial<ApiModel>>({
      queryFn: async (data) => {
        try {
          await ServiceRegDrugs.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
  }),
});
